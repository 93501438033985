import React, { useState, useEffect } from "react";

import {
  puneWildlifeImg,
  timelapseImagery,
} from "assets/data/track-your-forests-pune";

import { puneListofSpices } from "assets/data/track-your-forests-pune";

import Boschlayout from "../../boschlayout/inner";

import { slice } from "lodash";

import SEO from "components/seo/index";

import { Link } from "gatsby";

import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 3,
    partialVisibilityGutter: -10,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: -10,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
    partialVisibilityGutter: -10,
  },
};

const ButtonGroup = ({ next, previous }) => {
  return (
    <div className="carousel-button-group">
      <div className="arrow-navigations custom-arrow-navigation-event">
        <button className="pr-custom" onClick={() => previous()}>
          <i className="icon icon-arrow-left-short"></i>
        </button>
        <button className="pr-rightcustom" onClick={() => next()}>
          <i className="icon icon-arrow-right-short"></i>
        </button>
      </div>
    </div>
  );
};

export default function PuneForest() {
  const [galleryImages2, setGalleryImages] = useState([]);
  const [timelapseImages, setTimelapseImages] = useState([]);
  const [listofSpices, setListOfSpices] = useState([]);
  const [limit, setLimit] = useState(20);
  const [show, setShow] = useState(true);

  const [daysElapsed, setDaysElapsed] = useState([]);
  const date1 = new Date("11/1/2022");
  const date2 = new Date();

  function getDifferenceInDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  }

  useEffect(() => {
    setGalleryImages(slice(puneWildlifeImg, 0, 6));
    setTimelapseImages(slice(timelapseImagery, 0, 6));
    setDaysElapsed(getDifferenceInDays(date1, date2));
  }, []);

  useEffect(() => {
    const loadMore = () => {
      setListOfSpices(slice(puneListofSpices, 0, limit));
      if (limit >= puneListofSpices.length) setShow(false);
      else setShow(true);
    };
    loadMore();
  }, [limit]);
  return (
    <>
      <SEO title="BGSW Forest Plantation Drive" />
      <Boschlayout>
        <div className="track-forests-banner pb-0">
          <div className="breadCrumb">
            <div className="breadCrumbText">
              <Link to="/bgsw">HOME</Link>&nbsp;/&nbsp;
              <Link to="/track-your-forests">TRACK YOUR FORESTS</Link>
              &nbsp;/&nbsp; PUNE
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <h3 className="title-theme-heading text-bgsw">
                  {" "}
                  TRACK YOUR FORESTS<span className="dis-block">
                    {" "}
                    - Pune
                  </span>{" "}
                </h3>

                <p className="content-p-text banner-p-content">
                  {" "}
                  List of species and their count:
                </p>
                <p className="content-p-text banner-p-content">
                  {" "}
                  The mini-forest at Gohre Khurd <b> near Pune, </b> is created
                  by associates of <b> Bosch Global Software Technologies </b>{" "}
                  in collaboration with <b> Forests By Heartfulness. </b> It
                  comprises of over 2000 trees and shrubs belonging to 80
                  different species, all native to the Indian Sub-Continent. The
                  objective is to conserve <b> native & endangered </b> Indian
                  tree species including those listed in IUCN Redlist. The{" "}
                  <b> Heartyculture High Dense Forest (HCHD) </b> methodology
                  used for developing this garden ensures exponential growth of
                  plants within a short span of time. This biodiverse garden
                  aims to attract native bees, butterflies, birds, small mammals
                  like squirrels and bats.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 forest-species">
              <table>
                <thead>
                  <th>Scientific Name</th>
                  <th>Common Name</th>
                  <th>IUCN Assessment</th>
                  <th>Remarks</th>
                </thead>
                <tbody>
                  {listofSpices.map((item) => {
                    return (
                      <tr>
                        <td>{item.scientific_name}</td>
                        <td>{item.common_name}</td>
                        <td>{item.icun_assessment}</td>
                        <td>{item.remarks}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {show ? (
                <div className="loadMore">
                  <button onClick={() => setLimit(limit + 20)}>
                    Load More
                  </button>
                </div>
              ) : (
                <div className="loadMore">
                  <button onClick={() => setLimit(limit - 20)}>
                    Load Less
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="row object-section-row forest-thesis">
              <div className="col-md-2 col-sm-4">
                <h2 className="forest-thesis-title">
                  {daysElapsed}
                  <span className="dis-block"> Days</span>
                </h2>
                <p className="content-p-text">
                  Days elapsed from first day of plantation
                </p>
              </div>
              <div className="col-md-2 col-sm-4">
                <h2 className="forest-thesis-title">
                  400<span className="dis-block"> Tons</span>
                </h2>
                <p className="content-p-text">
                  Carbon sequestration Potential of this forest in next 20 years
                  <sup>^</sup>
                </p>
              </div>
              <div className="col-md-2 col-sm-4">
                <h2 className="forest-thesis-title">
                  TBD <span className="dis-block"> Ton</span>
                </h2>
                <p className="content-p-text">
                  Carbon sequestered This section will be enabled after 6 months
                  of plantation
                </p>
              </div>
              <div className="col-md-2 col-sm-4">
                <h2 className="forest-thesis-title">
                  92%<span className="dis-block"> Percentage</span>
                </h2>
                <p className="content-p-text">
                  Survival rate of saplings planted<sup>#</sup>
                </p>
              </div>
              <div className="col-md-2 col-sm-4">
                <h2 className="forest-thesis-title">
                  6<span className="dis-block"> Feet</span>
                </h2>
                <p className="content-p-text">
                  Average height of the forest<sup>*</sup>
                </p>
              </div>
            </div>
            <div className="col-md-12 forest-thesis-list">
              <p className="content-p-text p-content">
                <sup>^</sup> - Forests By Heartfulness uses allometric equations
                for biomass estimation, that is used to derive the carbon
                sequestered, both actual &amp; potential. The key parameters
                that go into this equation includes tree species, girth or
                Diameter at Breast Height and Height of the tree.
              </p>
              <p className="content-p-text p-content">
                <sup>*</sup> - Based on quarterly observation. Next observation
                due on 3 months from last observation. First observation is on
                plantation day
              </p>
              <p className="content-p-text p-content">
                <sup>#</sup> - FBH team aims to keep the survival rate above 95%
                of the initially rated capacity of the forest. If there are more
                casualties, the team replaces them with new saplings to keep the
                count above 95%.
              </p>
            </div>
          </div>
        </div>
      </Boschlayout>
    </>
  );
}
