import rp1 from "assets/images/wildlife-sighted-hyderabad/DSC4526.png";
import rp2 from "assets/images/wildlife-sighted-hyderabad/IMG_0013.png";
import rp3 from "assets/images/wildlife-sighted-hyderabad/20190501_MON_3308.png";
import rp4 from "assets/images/wildlife-sighted-hyderabad/IMG_5945.png";
import rp5 from "assets/images/wildlife-sighted-hyderabad/Nursery.png";
import rp6 from "assets/images/wildlife-sighted-hyderabad/Rain_forest.png";

export const puneWildlifeImg = [
  {
    id: 1,
    img: rp1,
    alt: "",
  },
  {
    id: 2,
    img: rp2,
    alt: "",
  },
  {
    id: 3,
    img: rp3,
    alt: "",
  },
  {
    id: 4,
    img: rp4,
    alt: "",
  },
  {
    id: 5,
    img: rp5,
    alt: "",
  },
  {
    id: 6,
    img: rp6,
    alt: "",
  },
];

export const timelapseImagery = [
  {
    id: 1,
    img: rp4,
    alt: "Picture taken on plantation day",
  },
  {
    id: 2,
    img: rp5,
    alt: "Picture taken 3 months after",
  },
  {
    id: 3,
    img: rp6,
    alt: "Picture taken 6 months after",
  },
  {
    id: 4,
    img: rp1,
    alt: "",
  },
  {
    id: 5,
    img: rp2,
    alt: "",
  },
  {
    id: 6,
    img: rp3,
    alt: "",
  },
];

export const puneListofSpices = [
  {
    id: 1,
    scientific_name: "Syzygium travanicorium",
    common_name: "Poriyal",
    icun_assessment: "Critically Endangered",
    remarks: "Less than 25 individual trees on Earth",
  },
  {
    id: 2,
    scientific_name: "Aqillaria agallocha",
    common_name: "Agarwood",
    icun_assessment: "Critically Endangered",
    remarks: "Used in the preparation of cosmetics & perfumes",
  },
  {
    id: 3,
    scientific_name: "Vateria indica",
    common_name: "White Dammar",
    icun_assessment: "Vulnerable",
    remarks: "Highly medicinal for treating respiratory & skin ailments",
  },
  {
    id: 4,
    scientific_name: "Nothapodytes Nimmoniana",
    common_name: "Ghanera",
    icun_assessment: "Endemic",
    remarks:
      "Wide spectrum of medicinal properties including anti- cancer, anti-AIDS etc",
  },
  {
    id: 5,
    scientific_name: "Garcinia indica",
    common_name: "Kokum",
    icun_assessment: "Endemic and Vulnerable",
    remarks: "Super food for gut health and treat stomach ailments",
  },
  {
    id: 6,
    scientific_name: "Artocarpus hirsutus",
    common_name: "Wild Jack",
    icun_assessment: "Endemic",
    remarks: "Source of valuable timber and medicine",
  },
  {
    id: 7,
    scientific_name: "Magnolia champaca",
    common_name: "Champaka",
    icun_assessment: "Endemic",
    remarks:
      "Used to treat diarrhea, cough, bronchitis, hypertension, dyspepsia",
  },
  {
    id: 8,
    scientific_name: "Terminalia  chebula",
    common_name: "Gall-nut/Ink Nut",
    icun_assessment: "Endemic",
    remarks: "Highly medicinal used for treating digestive problems",
  },
  {
    id: 9,
    scientific_name: "Feronia elephantum",
    common_name: "Wood Apple",
    icun_assessment: "Near Threatened",
    remarks: "Used as Liver & Cardiac tonic",
  },
  {
    id: 10,
    scientific_name: "Boswellia serrata",
    common_name: "Indian frankincense",
    icun_assessment: "Near Threatened",
    remarks: "Used to treat inflammation",
  },
];
